import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment";
Chart.register(...registerables);

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCOf2hWjR_LfwMLqJXToRajJW_Vw7F_Ko0",
  authDomain: "terminaltime-d4c37.firebaseapp.com",
  projectId: "terminaltime-d4c37",
  storageBucket: "terminaltime-d4c37.appspot.com",
  messagingSenderId: "618657224306",
  appId: "1:618657224306:web:9fd03f28c38ddf6c8a14eb",
  measurementId: "G-3X9HF8FP9C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const db = getFirestore(app);

const chartData = {
  datasets: [
    {
      id: 5,
      label: "Terminal 2",
      backgroundColor: "red",
      borderColor: "red",
    },
    {
      id: 7,
      label: "Terminal 3",
      backgroundColor: "blue",
      borderColor: "blue",
    },
    {
      id: 8,
      label: "Terminal 4",
      backgroundColor: "green",
      borderColor: "green",
    },
    {
      id: 15,
      label: "Terminal 5F",
      backgroundColor: "pink",
      borderColor: "pink",
    },
    {
      id: 10,
      label: "Terminal 5D",
      backgroundColor: "purple",
      borderColor: "purple",
    },
  ].map((d) =>
    Object.assign({}, d, {
      borderWidth: 1.5,
      pointRadius: 0,
      hitRadius: 3,
      labelWithoutTime: d.label,
      data: [],
    })
  ),
};

const getProjectedMinutes = (dbData) =>
  Math.round(dbData.CurrentProjectedQueueTime / 60);

const urlParams = new URLSearchParams(window.location.search);
const daysParam = urlParams.get('days');
const days = daysParam || 1

const startDate = new Date()

startDate.setHours(startDate.getHours() - (days*24))

const xUnit = days > 1 ? 'day' : 'hour'

const q = query(collection(db, "data"), where("Date", ">=", startDate), orderBy("Date", "asc"));

getDocs(q).then((querySnapshot) => {
  querySnapshot.forEach((doc) => {
    const dbData = doc.data();
    const chartDataset = chartData.datasets.find(
      (dataset) => dataset.id === dbData.Id
    );
    chartDataset.data.push({
      x: dbData.Date.toDate(),
      y: getProjectedMinutes(dbData),
    });

    chartDataset.label = `${dbData.LocationName} (${getProjectedMinutes(
      dbData
    )}min)`;
  });

  const config = {
    type: "line",
    data: chartData,
    options: {
      animation: {
        duration: 0,
      },
      /** FIX THIS */
      onResize: (chart,size) => {
        console.log("asd",chart,size)
        chart.innerWidth =  size.width
        chart.resize()
        // chart
      },
      maintainAspectRatio: false,
      responsive: true,
      interaction: {
        intersect: false,
        mode: "index",
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) =>
              `${tooltipItem.dataset.labelWithoutTime}: ${tooltipItem.parsed.y}min`,
          },
        },
      },
      scales: {
        x: {
          type: "time",
          time: {
            unit: xUnit,
            tooltipFormat: "MMMM Do HH:mm",
            displayFormats: {
              hour: "DD/MM HH:mm",
            },
          },

          title: {
            display: true,
            color: "purple",
            text: "Teminal Time by !Not",
          },
        },
      },
    },
  };
  let chartRef = new Chart(document.getElementById("arlandaChart"), config);
  let oneDay = document.getElementById("oneDay")
  let sevenDay = document.getElementById("sevenDay")
  oneDay.addEventListener("click", setDays(1));
  sevenDay.addEventListener("click", setDays(7));
});
function setDays (days = 7) {
    return function(_e){
      const params = new URLSearchParams(location.search);
      params.set('days',days);
    
      window.history.replaceState({}, '', `${location.pathname}?${params}`);
      location.reload()
  }
}





